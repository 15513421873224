import { fetcher } from '@/api/fetcher';
import { AssistantTeamMembers } from '@/types';
import { baseControlUrl } from './baseControlUrl';

interface QueryParamsType {
  queryParams: {
    groupId: string;
  };
}

export const getAssistantTeamMembers = async ({
  queryParams,
}: QueryParamsType): Promise<AssistantTeamMembers[]> => {
  const { data } = await fetcher.get(`${baseControlUrl}list-teams/members`, {
    searchParams: queryParams ? { ...queryParams } : undefined,
  });

  return data;
};
