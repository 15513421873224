import { Flex, Tooltip, Box, TooltipProps } from '@grupoboticario/flora-react';
import {
  ExclamationMarkHexagonIcon,
  QuestionMarkCircleIcon,
} from '@grupoboticario/flora-react-icons';

interface HelperTooltipProps extends TooltipProps {
  isQuestionMark?: boolean;
}

export const HelperTooltip = ({
  align = 'start',
  direction = 'top',
  showArrow = true,
  size = 'standard',
  text,
  isQuestionMark = false,
  ...rest
}: HelperTooltipProps) => (
  <Flex align='center' css={{ paddingLeft: '$1' }}>
    <Tooltip
      data-testid='tooltip'
      align={align}
      direction={direction}
      showArrow={showArrow}
      size={size}
      text={text}
      {...rest}
    >
      <Box css={{ width: 'auto', color: '$backgroundAltPrimary' }}>
        {isQuestionMark ? (
          <QuestionMarkCircleIcon size='16' aria-label={text} />
        ) : (
          <ExclamationMarkHexagonIcon size='16' aria-label={text} />
        )}
      </Box>
    </Tooltip>
  </Flex>
);
