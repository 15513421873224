import { OperationEnum } from '@/types';
import { Flex, Tag, TagProps } from '@grupoboticario/flora-react';
import { RoundCircleClockIcon } from '@grupoboticario/flora-react-icons';

interface FilterTagProps {
  filterName: OperationEnum;
  isRecadastro: boolean;
}

interface OperationTagDataProps {
  variant: TagProps['variant'];
  name: string;
}

const operationTagData: Record<OperationEnum, OperationTagDataProps> = {
  [OperationEnum.BOTI]: {
    variant: 'success',
    name: 'BOT',
  },
  [OperationEnum.VDF]: {
    variant: 'error',
    name: 'VDF',
  },
  [OperationEnum.CII]: {
    variant: 'alert',
    name: 'CII',
  },
};

export const OperationTag = ({ filterName, isRecadastro }: FilterTagProps): JSX.Element | null => (
  <Flex gap='$2' justify='space-between'>
    <Tag variant={operationTagData[filterName].variant} css={{ width: '$10' }}>
      {operationTagData[filterName].name}
    </Tag>
    {isRecadastro ? (
      <Tag
        alt='false'
        shape='ghost'
        size='medium'
        variant='assorted9'
        leftIcon={<RoundCircleClockIcon size={48} />}
      >
        Rec
      </Tag>
    ) : null}
  </Flex>
);
