interface GA3Event {
  event?: 'event';
  action: string;
  category: string;
  label: string;
}

interface GA4Event {
  event: 'analytics-event';
  event_parameters: Record<string, string | undefined>;
}

type Event = GA4Event | GA3Event;

enum InteractionDetail {
  click = 'click',
  checkbox = 'checkbox',
}

enum EventName {
  INTERACTION_MAILING = 'interaction_mailing',
  INTERACTION_MAILING_FILTERS = 'interaction_mailing_filtros',
}

export { type GA4Event, type GA3Event, type Event, InteractionDetail, EventName };
