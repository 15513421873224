import { MailingStoreAssistantStateProps } from '@/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { assistantMailingSlice } from './assistantMailingSlice';

export const useAssistantMailingStore = create<MailingStoreAssistantStateProps>()(
  devtools((...state) => ({
    ...assistantMailingSlice(...state),
  }))
);
