import { useEffect } from 'react';

export const useDisableTextSelection = () => {
  useEffect(() => {
    const handleSelectStart = (event: MouseEvent) => {
      event.preventDefault();
    };

    document.addEventListener('selectstart', handleSelectStart);
    document.body.style.userSelect = 'none';

    return () => {
      document.removeEventListener('selectstart', handleSelectStart);
      document.body.style.userSelect = '';
    };
  }, []);
};
