import { ControlQueryKey } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { getAssistantTeams } from '../getAssistantTeams';

export const useGetAssistantTeams = () => {
  const fetchQuery = useQuery({
    queryKey: [ControlQueryKey.ASSISTANT_TEAMS],
    queryFn: getAssistantTeams,
  });

  return {
    ...fetchQuery,
    data: fetchQuery.data,
  };
};
