import { FilterTagList } from '@/shared';
import { useControlMailingStore } from '@/store/control';
import { FilterOptions } from '@/types';
import { useShallow } from 'zustand/react/shallow';
import { filterTagToString } from './constants';
import { Box } from '@grupoboticario/flora-react';
import { formatterFilterTagToString } from './functions';

const convertToFilterTag = (filterList: FilterOptions): string[] =>
  !!filterList &&
  Object.entries(filterList).flatMap(([key, value]) =>
    Array.isArray(value) ? value.map((v) => `${key}:${v}`) : `${key}:${value}`
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatterFilterTag = (filterTag: FilterOptions): any[] => {
  if (!filterTag) {
    return [];
  }
  return convertToFilterTag(filterTag).map((item) => ({
    name: filterTagToString[item] || formatterFilterTagToString(item).name,
    key: item,
  }));
};

export const ControlFiler = () => {
  const { controlFilter } = useControlMailingStore(useShallow((state) => state));

  return (
    <Box>
      <FilterTagList filterTagResult={formatterFilterTag(controlFilter)} variant='disabled' />
    </Box>
  );
};
