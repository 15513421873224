import { FilterOptions } from '@/types';
import { TZDate } from '@date-fns/tz';
import { endOfDay, startOfDay } from 'date-fns';

const formatterInputSendValue = (value: string) =>
  !!value && value.length ? value?.split(';').map((part) => part.trim()) : undefined;

const formatterInputMultiValue = (value: string[]): string[] =>
  value?.map((v) => v.toString()).join(';') as unknown as string[];

const formatterDateInput = (startOrEndOfDay: 'startOfDay' | 'endOfDay', date: Date): Date => {
  if (!date || !startOrEndOfDay) {
    return undefined;
  }
  return startOrEndOfDay === 'startOfDay'
    ? startOfDay(new TZDate(date, 'America/Sao_Paulo'))
    : endOfDay(new TZDate(date, 'America/Sao_Paulo'));
};

export const formatterDefaultValues = (data: FilterOptions) => {
  if (!data) {
    return undefined;
  }

  return {
    ...data,
    cpCode: formatterInputMultiValue(data.cpCode),
    assistants: formatterInputMultiValue(data.assistants),
  };
};

export const formatterFilter = (data: FilterOptions) => {
  const formattedFilter: FilterOptions = {
    ...data,
    cpCode: formatterInputSendValue(data.cpCode as unknown as string),
    assistants: formatterInputSendValue(data.assistants as unknown as string),
    startDate: formatterDateInput('startOfDay', data.startDate),
    endDate: formatterDateInput('endOfDay', data.endDate),
    startDateDistributionData: formatterDateInput('startOfDay', data.startDateDistributionData),
    endDateDistributionData: formatterDateInput('endOfDay', data.endDateDistributionData),
    reRegistrationStartDate: formatterDateInput('startOfDay', data.reRegistrationStartDate),
    reRegistrationEndDate: formatterDateInput('endOfDay', data.reRegistrationEndDate),
    label: data.label?.trim().toLocaleLowerCase(),
  };

  const cleanupFilter = Object.entries(formattedFilter).reduce((acc, [key, value]) => {
    if (
      value !== undefined &&
      value !== 'undefined' &&
      value &&
      (!Array.isArray(value) || value.length > 0)
    ) {
      acc[key as keyof FilterOptions] = value;
    }
    return acc;
  }, {} as FilterOptions);

  return cleanupFilter;
};
