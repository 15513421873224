import { FloraProvider } from '@grupoboticario/flora-react';
import { gbInstitucionalTheme } from '@grupoboticario/flora';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './utils';
import { Mailing, IrDistributionFilter, AssistantTeamMembersList } from './pages';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PageWrapper } from './shared';

export default function Root() {
  return (
    <FloraProvider theme={gbInstitucionalTheme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename='/inicios/mailing'>
          <Routes>
            <Route path='/atendente' element={<PageWrapper title='Segmentação de base - IRs' />}>
              <Route path='base-de-irs' element={<Mailing />} />
            </Route>
            <Route path='/control' element={<PageWrapper title='Distribuição de base - IRs' />}>
              <Route path='distribuicao-irs' element={<IrDistributionFilter />} />
              <Route path='selecionar-assistentes' element={<AssistantTeamMembersList />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </FloraProvider>
  );
}
