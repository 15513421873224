import { fetcher } from '@/api/fetcher';

export enum AttendanceStatus {
  OPEN = 'open',
  IN_ATTENDANCE = 'inAttendance',
  CANCELED = 'canceled',
  FINISHED = 'finished',
  CLOSED_BY_TIME = 'closedByTime',
}

export interface PostServiceParams {
  resellerId: string[];
  status?: AttendanceStatus;
}

export async function postServices(req: PostServiceParams): Promise<string[]> {
  const { data } = await fetcher.post('services/start-services', { body: req });
  return data;
}
