export const baseMediaSourcePaidValueOptions = [
  { label: 'Pago - Afiliados', value: 'afiliados - pago' },
  { label: 'Pago - Facebook', value: 'facebook - pago' },
  { label: 'Pago - Google', value: 'google - pago' },
  { label: 'Pago - Offline', value: 'offline - pago' },
  { label: 'Pago - Outros', value: 'outros - pago' },
  { label: 'Pago - TikTok', value: 'tiktok - pago' },
  { label: 'Mkt Regional', value: 'mkt regional' },
  { label: 'Orgânico - Portais', value: 'portais - organico' },
  { label: 'Orgânico - Outros', value: 'outros - organico' },
];
