export enum TextColorsEnum {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  NO_COLOR = 'noColor',
}

export interface TextInfoProps {
  title: string;
  value: string;
  color?: TextColorsEnum;
}
