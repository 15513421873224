import { Box, Flex, Heading } from '@grupoboticario/flora-react';
import { Outlet } from 'react-router-dom';

interface PageWrapperProps {
  title: string;
}

export const PageWrapper = ({ title }: PageWrapperProps) => {
  return (
    <Box css={{ margin: '$2 $4', padding: '0 $6' }}>
      <Flex css={{ justifyContent: 'space-between', alignItems: 'center', margin: '$9 0 $6' }}>
        <Heading
          level={6}
          as='h2'
          css={{
            fontWeight: '500',
          }}
        >
          {title}
        </Heading>
      </Flex>
      <Outlet />
    </Box>
  );
};
