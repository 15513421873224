import { instance } from '@/api/fetcher';

type MethodType = 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';

export interface FetcherOptions {
  timeout?: number;
  method?: MethodType;
  body?: unknown;
  searchParams?: {
    [key: string | number]: string | number | boolean;
  };
  headers?: {
    [key: string]: string;
  };
  apiVersion?: number;
  responseType?: 'json' | 'text' | 'blob';
}

function fetcherFunction(url: string, method: MethodType, options?: FetcherOptions) {
  return instance({
    method,
    data: options?.body,
    url: options?.apiVersion ? `v${options.apiVersion}/${url}` : `v1/${url}`,
    headers: options?.headers,
    params: options?.searchParams,
  });
}

const fetcherGet = async (url: string, options?: FetcherOptions) =>
  fetcherFunction(url, 'GET', options);
const fetcherPost = async (url: string, options?: FetcherOptions) =>
  fetcherFunction(url, 'POST', options);
const fetcherPut = async (url: string, options?: FetcherOptions) =>
  fetcherFunction(url, 'PUT', options);
const fetcherDelete = async (url: string, options?: FetcherOptions) =>
  fetcherFunction(url, 'DELETE', options);
const fetcherPatch = async (url: string, options?: FetcherOptions) =>
  fetcherFunction(url, 'PATCH', options);

const fetcher = {
  delete: fetcherDelete,
  get: fetcherGet,
  patch: fetcherPatch,
  post: fetcherPost,
  put: fetcherPut,
};

export { fetcher };
