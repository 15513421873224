import { KeyNames, PrefixNames } from '../../enuns';

export const formatterTagValueFromInput = (
  inputValue: string,
  keyNames: KeyNames,
  prefixNames: PrefixNames
): Record<string, string> => {
  const [key, value] = inputValue.split(':');
  if (key === keyNames) {
    return {
      name: `${prefixNames}${value}`,
    };
  }
};
