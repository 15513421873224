import { useGetMetaData } from '@/api/services/control';
import { TZDate } from '@date-fns/tz/date';
import { Text, TextProps } from '@grupoboticario/flora-react';
import { format } from 'date-fns/format';

const textProps: TextProps = {
  size: 'bodyLargeShortMedium',
  as: 'p',
  css: {
    paddingBottom: '$2',
  },
};

export const LatestBaseIRUpdateInfo = () => {
  const { data, isLoading, error } = useGetMetaData();
  if (isLoading || error) {
    return (
      <Text {...textProps}>
        {isLoading && 'Aguarde enquanto as informações da última atualização são carregadas'}
        {error && 'Não foi possível carregar as informações da última atualização.'}
      </Text>
    );
  }

  const date = format(
    new TZDate(data[0].mailingLastUpdate, 'America/Sao_Paulo'),
    "'Base atualizada pela última vez às' HH:mm 'do dia' dd/MM/yyyy"
  );

  return <Text {...textProps}>{date}</Text>;
};
