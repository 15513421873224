import { Tag } from '@grupoboticario/flora-react';
import { InformationCircleIcon } from '@grupoboticario/flora-react-icons';

interface TagInfoProps {
  text: string;
}

export const TagInfo = ({ text }: TagInfoProps) => (
  <Tag
    alt='false'
    shape='ghost'
    size='medium'
    variant='alert'
    leftIcon={<InformationCircleIcon />}
    css={{
      color: '$$colors$nonInteractivePredominant',
      '& span': {
        color: '$colors$actionAlertStatic',
      },
    }}
  >
    {text}
  </Tag>
);
