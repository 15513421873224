export const toPercent = (baseValue: string, valueToPercent: string) => {
  const baseValueNumber = Number(baseValue);
  const valueToPercentNumber = Number(valueToPercent);

  if (baseValueNumber === 0) {
    return '0%';
  }

  const percent = (valueToPercentNumber / baseValueNumber) * 100;

  return `${percent.toFixed(0)}%`;
};
