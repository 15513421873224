import {
  baseMediaSourcePaidValueOptions,
  operationOptions,
  riskAnalysisOptions,
  singleSelectOptions,
  tabulationOptions,
  uf,
} from '../../side-sheet-filter';
import { KeyNames, PrefixNames } from '../enuns';
import { formatterTagValueFromSelect } from '../functions';

const riskLevelToString = formatterTagValueFromSelect(
  riskAnalysisOptions,
  KeyNames.riskAnalysis,
  PrefixNames.risk
);

const operationToString = formatterTagValueFromSelect(
  operationOptions,
  KeyNames.operation,
  PrefixNames.operation
);

const recadastroToString = formatterTagValueFromSelect(
  singleSelectOptions,
  KeyNames.recadastro,
  PrefixNames.recadastro
);

const haveCreditToString = formatterTagValueFromSelect(
  singleSelectOptions,
  KeyNames.haveCredit,
  PrefixNames.haveCredit
);

const haveIrAssignedToString = formatterTagValueFromSelect(
  singleSelectOptions,
  KeyNames.haveIrAssigned,
  PrefixNames.haveIrAssigned
);

const baseMediaSourceToString = formatterTagValueFromSelect(
  baseMediaSourcePaidValueOptions,
  KeyNames.baseMediaSource,
  PrefixNames.baseMediaSource
);

const tabulationToString = formatterTagValueFromSelect(
  tabulationOptions,
  KeyNames.tabulation,
  PrefixNames.tabulation
);

const ufToString = formatterTagValueFromSelect(
  uf,
  KeyNames.federativeUnit,
  PrefixNames.federativeUnit
);

export const filterTagToString: Record<string | number | symbol, string> = {
  ...haveCreditToString,
  ...haveIrAssignedToString,
  ...operationToString,
  ...recadastroToString,
  ...riskLevelToString,
  ...baseMediaSourceToString,
  ...ufToString,
  ...tabulationToString,
};
