import { useEvents } from '@/shared';
import { FloraCheckbox, FloraCheckboxProps } from '@grupoboticario/flora-react';
import { ChangeEvent } from 'react';

interface ActionCellProps extends FloraCheckboxProps {
  isSelected: boolean;
}

export const ActionCell = ({ isSelected, ...rest }: ActionCellProps) => {
  const event = useEvents();
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    rest.onChange(e);
    return !isSelected && event.interactionCheckbox(rest.id);
  };
  return (
    <FloraCheckbox {...rest} checked={isSelected} onChange={onChange} data-testid='row-checkbox' />
  );
};
