import {
  AttendanceStatus,
  useMassStartService,
  useOpenServicesCount,
} from '@/api/services/mailing';
import { useEvents } from '@/shared';
import { useAssistantMailingStore } from '@/store/assistant';
import { AssistantModalOpenType } from '@/types';
import { displayToaster } from '@/utils';
import { Flex, useClipboard, FloraButton as Button, CSS } from '@grupoboticario/flora-react';
import { ChatBubbleIcon, PersonPhoneCardIcon, PersonIcon } from '@grupoboticario/flora-react-icons';
import { useShallow } from 'zustand/react/shallow';

export const AssistantTableMenu = () => {
  const clipboard = useClipboard();
  const { totalOpenServices } = useOpenServicesCount();
  const { tableMailing, setAssistantModalOpen } = useAssistantMailingStore(
    useShallow((state) => state)
  );
  const { handleStartService } = useMassStartService();
  const tableState = tableMailing.tableState;
  const { rows } = tableState.getSelectedRowModel();
  const events = useEvents();

  const ableToOpenServices = totalOpenServices?.maxOpenServices - totalOpenServices?.total;
  const openingServices = rows?.length;
  const canOpenServices = ableToOpenServices >= openingServices;
  const selectedRows = rows.length;
  const hasSelectedRows = selectedRows > 0;

  const buttonCss: CSS = {
    height: '$10',
    '& svg': {
      scale: '1.2',
    },
  };

  const handleOpenAttendance = async (attendanceModalStatus: AssistantModalOpenType) => {
    if (!canOpenServices) {
      return displayToaster({
        kind: 'error',
        message: `O limite de ${totalOpenServices?.maxOpenServices} atendimentos foi atingido. Encerre os atendimentos atuais para iniciar novos atendimentos.`,
      });
    }
    if (rows.length > 1) {
      return setAssistantModalOpen(true, attendanceModalStatus);
    }
    handleStartService(attendanceModalStatus);
  };

  const handleCopyPhone = () => {
    const textFormatted = rows.length > 1 ? 'Telefones copiados' : 'Telefone copiado';
    const phoneNumbers = rows.map((row) => row.original.phone).join('\n');
    clipboard.copy(phoneNumbers);

    displayToaster({
      message: `${textFormatted} com sucesso`,
    });
  };

  return (
    <Flex align='center' gapX='$4' css={{ height: '40px' }}>
      {hasSelectedRows ? (
        <Flex css={{ paddingLeft: '$6' }} align='center' gapX='$2'>
          <Button
            hierarchy='secondary'
            size='medium'
            icon={<PersonPhoneCardIcon css={{ color: '$linkDefault' }} />}
            has='iconLeft'
            data-testid='icon-button-menu'
            css={buttonCss}
            onClick={() => {
              handleCopyPhone();
              events.interactionCheckBoxMenu('click:checkbox:copiar_telefone');
            }}
          >
            Copiar telefone
          </Button>

          <Button
            hierarchy='secondary'
            size='medium'
            icon={<PersonIcon css={{ color: '$linkDefault' }} />}
            has='iconLeft'
            data-testid='icon-button-menu'
            css={buttonCss}
            onClick={() => {
              handleOpenAttendance(AttendanceStatus.OPEN);
              events.interactionCheckBoxMenu('click:checkbox:dados_da_ir');
            }}
          >
            Ver dados
          </Button>

          <Button
            hierarchy='secondary'
            size='medium'
            icon={<ChatBubbleIcon css={{ color: '$linkDefault' }} />}
            has='iconLeft'
            data-testid='icon-button-menu'
            css={buttonCss}
            onClick={() => {
              handleOpenAttendance(AttendanceStatus.IN_ATTENDANCE);
              events.interactionCheckBoxMenu('click:checkbox:iniciar_atendimento');
            }}
          >
            Iniciar atendimento
          </Button>
        </Flex>
      ) : null}
    </Flex>
  );
};
