import { SituationStatus, SituationStatusValue } from '@/types';

const SITUATION_PREFIX = 'situation:';
export const situationOptions = [
  {
    label: SituationStatus.NOT_STARTED,
    value: `${SITUATION_PREFIX}${SituationStatusValue.NOT_STARTED}`,
  },
  {
    label: SituationStatus.WRONG_REGISTER,
    value: `${SITUATION_PREFIX}${SituationStatusValue.WRONG_REGISTER}`,
  },
  {
    label: SituationStatus.IN_SERVICE,
    value: `${SITUATION_PREFIX}${SituationStatusValue.IN_SERVICE}`,
  },
  {
    label: SituationStatus.WRONG_NUMBER,
    value: `${SITUATION_PREFIX}${SituationStatusValue.WRONG_NUMBER}`,
  },
  {
    label: SituationStatus.DENIED_CALL,
    value: `${SITUATION_PREFIX}${SituationStatusValue.DENIED_CALL}`,
  },
  {
    label: SituationStatus.WITHOUT_CALL,
    value: `${SITUATION_PREFIX}${SituationStatusValue.WITHOUT_CALL}`,
  },
  {
    label: SituationStatus.SALE_COMPLETED,
    value: `${SITUATION_PREFIX}${SituationStatusValue.SALE_COMPLETED}`,
  },
];
