import { AttendanceStatus, useMassStartService } from '@/api/services/mailing';
import { Divider } from '@/shared';
import { useAssistantMailingStore } from '@/store/assistant';
import {
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FloraButton as Button,
  FloraTypography,
} from '@grupoboticario/flora-react';
import { useShallow } from 'zustand/react/shallow';

export const StartServiceModal = () => {
  const { assistantModalOpen, setAssistantModalOpen, attendanceStatus } = useAssistantMailingStore(
    useShallow((state) => state)
  );

  const { handleStartService } = useMassStartService();

  const modalHeaderText =
    attendanceStatus === AttendanceStatus.OPEN ? 'Visualizar dados' : 'Iniciar atendimento';
  const modalBodyText =
    attendanceStatus === AttendanceStatus.OPEN
      ? 'As consultas das IR selecionadas serão abertas. Esta ação redirecionará para a tela de atendimentos.'
      : 'Os atendimentos selecionados serão iniciados. Esta ação redirecionará para a tela de atendimentos.';

  return (
    <Modal
      open={assistantModalOpen}
      onOpenChange={() => {
        setAssistantModalOpen(false);
      }}
    >
      <ModalContent size='small'>
        <ModalCloseButton />

        <ModalHeader>
          <FloraTypography
            fontSize='subtitle'
            viewport='desktop'
            as='h6'
            data-testid='start-service-title'
          >
            {modalHeaderText}
          </FloraTypography>
        </ModalHeader>
        <ModalBody>
          <Divider css={{ marginBottom: '$4', marginTop: 0 }} />
          <FloraTypography
            as='p'
            fontSize='bodyLargeShort'
            css={{ padding: '0 $2', lineHeight: '1.2rem' }}
          >
            {modalBodyText}
          </FloraTypography>
        </ModalBody>
        <ModalFooter
          css={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            onClick={() => {
              handleStartService(attendanceStatus);
              setAssistantModalOpen(false);
            }}
            isFull
          >
            Continuar
          </Button>
          <ModalCloseButton asChild>
            <Button hierarchy='secondary' isFull>
              Cancelar
            </Button>
          </ModalCloseButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
