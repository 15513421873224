import { fetcher } from '@/api/fetcher';
import { GetMailingDataParams, GetMailingDataResponse } from '@/types';

export const getMailingData = async (
  queryParams?: GetMailingDataParams
): Promise<GetMailingDataResponse> => {
  const { data } = await fetcher.get('mailing/listIr', {
    searchParams: queryParams ? { ...queryParams } : undefined,
  });

  return data;
};
