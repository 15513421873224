const RECADASTRO_PREFIX = 'recadastro:';

export const recadastroOptions = [
  {
    label: 'Sim',
    value: `${RECADASTRO_PREFIX}true`,
  },
  {
    label: 'Não',
    value: `${RECADASTRO_PREFIX}false`,
  },
  {
    label: 'Todos',
    value: `${RECADASTRO_PREFIX}`,
  },
];
