import { ColorThemeProps } from '@grupoboticario/flora';
import { FloraTypography as Text, CSS, useClipboard } from '@grupoboticario/flora-react';
import { displayToaster } from '@/utils';

interface TextCellProps {
  text: string | number;
  color?: ColorThemeProps;
  css?: CSS;
  canCopyToClipboard?: boolean;
  copyToClipboardText?: string;
}

export const TextCell = ({
  text,
  color,
  css,
  canCopyToClipboard,
  copyToClipboardText,
}: TextCellProps) => {
  const clipboard = useClipboard();

  const handleCopy = () => {
    clipboard.copy(text.toString());
    displayToaster({
      message: copyToClipboardText ?? 'Texto copiado com sucesso',
    });
  };

  return (
    <Text
      color={color}
      fontSize='bodyLargeStandard'
      onClick={canCopyToClipboard ? handleCopy : undefined}
      css={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        cursor: canCopyToClipboard ? 'pointer' : 'default',
        color: canCopyToClipboard ? '$actionableDefault' : undefined,
        ...css,
      }}
    >
      {text}
    </Text>
  );
};
