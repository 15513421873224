import { MailingData, SituationStatusValue } from '@/types';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import { DropdownSituation } from '@/components';
import { ActionCell, OperationTag, RiskTag, TextCell } from '@/shared';
import { format } from 'date-fns';
import { formatPhoneNumber } from '@/utils';
import { TZDate } from '@date-fns/tz';

const columnHelper = createColumnHelper<MailingData>();

export const Columns: ColumnDef<MailingData>[] = [
  columnHelper.display({
    id: 'actions',
    enableSorting: false,
    cell: (props) => (
      <ActionCell
        isSelected={props.row.getIsSelected()}
        onChange={() => props.row.toggleSelected()}
        id={props.row.original.geraId}
        data-selected={props.row.getIsSelected()}
      />
    ),
    meta: {
      gridColumn: '100px',
    },
  }),
  columnHelper.accessor('startMailing', {
    header: 'Data de Atribuição',
    enableSorting: false,
    cell: (props) => (
      <TextCell
        text={
          props.getValue()
            ? format(new TZDate(props.getValue(), 'America/Sao_Paulo'), "HH:mm 'do dia' dd/MM/yyyy")
            : '---'
        }
      />
    ),
    meta: {
      helperTooltip: 'Data e hora em que a IR foi atribuída para seu atendimento.',
    },
  }),
  columnHelper.accessor('name', {
    header: 'Nome',
    cell: (props) => (
      <TextCell
        text={props.getValue().toLowerCase().split(' ')[0]}
        css={{
          textTransform: 'capitalize',
        }}
      />
    ),
  }),
  columnHelper.accessor('situation', {
    header: 'Situação',
    enableSorting: false,
    cell: (props) => (
      <DropdownSituation
        geraId={props.row.original.geraId}
        code={props.row.original.code}
        subreason={props.row.original.subreason}
        dropdownValue={props.getValue() as SituationStatusValue}
        key={props.row.original.situation}
      />
    ),
    meta: {
      helperTooltip: 'Escolha uma situação de atendimento da IR',
    },
  }),
  columnHelper.accessor('geraId', {
    header: 'Código',
    enableSorting: false,
    cell: (props) => <TextCell text={props.getValue()} />,
  }),
  columnHelper.accessor('phone', {
    header: 'Telefone',
    enableSorting: false,
    cell: (props) => {
      const phoneRecadastro = props.row.original.telRecadastro;
      return (
        <TextCell
          text={formatPhoneNumber(!!phoneRecadastro ? phoneRecadastro : props.getValue())}
          css={{ color: '$actionableDefault' }}
          canCopyToClipboard
          copyToClipboardText='Telefone copiado com sucesso'
        />
      );
    },
    meta: {
      helperTooltip:
        'Nas bases de recadastro, será informado o número atualizado da IR. Para verificar o número do primeiro cadastro, consulte os dados da IR na no Atendimento.',
    },
  }),
  columnHelper.accessor('riskAnalysis', {
    header: 'Risco',
    cell: (props) => <RiskTag riskAnalysis={props.getValue()} />,
  }),
  columnHelper.accessor('registryDate', {
    header: 'Cadastro',
    cell: (props) => (
      <TextCell
        text={props.getValue() ? format(new Date(props.getValue()), 'dd/MM/yyyy') : '---'}
      />
    ),
    meta: {
      helperTooltip: 'Nas bases de recadastro, será informada a data do último cadastro.',
    },
  }),
  columnHelper.accessor('operation', {
    header: 'Operação',
    cell: (props) => (
      <OperationTag
        filterName={props.getValue()}
        isRecadastro={!!props.row.original.isRecadastro}
      />
    ),
  }),
  columnHelper.accessor('label', {
    header: 'Campanha',
    enableSorting: false,
    cell: (props) => {
      return (
        <TextCell
          css={{ textTransform: 'capitalize' }}
          text={props.row.original.label?.toLocaleLowerCase() ?? '--'}
        />
      );
    },
  }),
];
