import { ControlQueryKey } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { getBaseIrsData } from '../getBaseIrsData';
import { useControlMailingStore } from '@/store/control';
import { useShallow } from 'zustand/react/shallow';

export const useGetBaseIrsData = () => {
  const { setBaseIrsData, controlFilter } = useControlMailingStore(useShallow((state) => state));

  const fetchQuery = useQuery({
    queryKey: [ControlQueryKey.BASE_IR, controlFilter],
    queryFn: () => getBaseIrsData(controlFilter),
  });

  return {
    ...fetchQuery,
    data: setBaseIrsData(fetchQuery.data),
  };
};
