import { Box, CSS } from '@grupoboticario/flora-react';

interface ContentWrapperProps {
  children: React.ReactNode;
  css?: CSS;
}

export const ContentWrapper = ({ children, css }: ContentWrapperProps) => (
  <Box
    css={{
      background: '$backgroundPrimary',
      borderRadius: '$medium',
      border: '1px solid $bscGray200',
      padding: '0 $6',
      ...css,
    }}
  >
    {children}
  </Box>
);
