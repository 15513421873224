import { FilterTagList } from '@/shared';
import { useAssistantMailingStore } from '@/store/assistant';
import { useShallow } from 'zustand/react/shallow';
import { filterTagToString } from './constans';
import { FilterTagType } from './types';

const filterNameFormatted = (filter: string) => filter.replace(/^[^:]+:\s*/, '') as FilterTagType;

const formatFilterTag = (filterList: string[]) => {
  if (!filterList) {
    return undefined;
  }
  return filterList?.map((filter) => ({
    name: filterTagToString[filterNameFormatted(filter)] || filterNameFormatted(filter),
    key: filter,
  }));
};

export const AssistantFilter = () => {
  const { setAssistantFilter, assistantFilter } = useAssistantMailingStore(
    useShallow((state) => state)
  );

  return (
    <FilterTagList
      filterTagResult={formatFilterTag(assistantFilter)}
      onChangeFilter={setAssistantFilter}
    />
  );
};
