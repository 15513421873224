interface Config {
  api: string;
}

const config: Config = process.env.MOCK
  ? {
      api: 'https://api.example.com/',
    }
  : {
      api: process.env.BFF_BASE_URL,
    };

export const { api } = config;
