import { Toaster } from '@grupoboticario/flora-react';
interface DisplayErrorToasterProps {
  message: string;
  kind?: 'neutral' | 'success' | 'alert' | 'error';
  callbackFunction?: () => void;
  duration?: number;
}

export function displayToaster({
  message,
  callbackFunction,
  kind = 'success',
  duration = 60000,
}: DisplayErrorToasterProps) {
  Toaster.notify({
    kind: kind,
    duration,
    button: 'Fechar',
    closeWhenClick: true,
    description: message,
    origin: 'right-top',
    buttons: callbackFunction ? ['Tentar Novamente'] : [],
    callbacks: callbackFunction ? [() => callbackFunction()] : [],
  });
}
