import { Flex, FlexProps, CSS } from '@grupoboticario/flora-react';

interface TableCellProps extends FlexProps {
  type?: 'cell' | 'header';
  isSelected?: boolean;
  index?: number;
  gridColumn?: string;
}
const headerBackgroundColor: CSS['color'] = '$backgroundSecondary';
const oddCellBackgroundColor: CSS['color'] = '$backgroundPrimary';
const evenCellBackgroundColor: CSS['color'] = '$backgroundSecondary';
const selectedCellBackgroundColor: CSS['color'] = '$colors$backgroundTertiary';

const getCellBackGroundColor = (rowIndex: number, isSelected: boolean) => {
  if (isSelected) {
    return selectedCellBackgroundColor;
  }

  return rowIndex % 2 === 0 ? oddCellBackgroundColor : evenCellBackgroundColor;
};

export const TableCell = ({
  type = 'cell',
  children,
  isSelected = false,
  gridColumn = '1',
  justify = 'left',
  index,
}: React.PropsWithChildren<TableCellProps>) => {
  return (
    <Flex
      align='center'
      justify={justify}
      css={{
        gridColumn: `span ${gridColumn}`,
        padding: '0 $6',
        height: type === 'cell' ? '$14' : '$16',
        backgroundColor:
          type === 'cell' ? getCellBackGroundColor(index, isSelected) : headerBackgroundColor,
        color: '$nonInteractivePredominant',
      }}
      data-testid='table-cell'
    >
      {children}
    </Flex>
  );
};
