import { Flex, Tag, TagProps } from '@grupoboticario/flora-react';
import { CrossIcon } from '@grupoboticario/flora-react-icons';

interface FilterTagProps {
  filterName: string;
  handleDelete: (filterName: string) => void;
  variant?: TagProps['variant'];
}

export const FilterTag = ({ filterName, handleDelete, variant }: FilterTagProps) => (
  <Tag
    shape='ghost'
    data-testid='filter-tag'
    rightIcon={
      variant !== 'disabled' ? (
        <Flex
          onClick={() => handleDelete(filterName)}
          as='span'
          justify='center'
          data-testid='delete-tag-button'
        >
          <CrossIcon />
        </Flex>
      ) : null
    }
    variant={variant ?? 'assorted9'}
  >
    {filterName}
  </Tag>
);
