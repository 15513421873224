export const formatPhoneNumber = (phoneNumber: string | undefined): string | undefined => {
  if (!phoneNumber) {
    return undefined;
  }

  const onlyDigitsNumber = phoneNumber.replace(/\D+/g, '');

  const cleanedNumber = onlyDigitsNumber.startsWith('55')
    ? onlyDigitsNumber.slice(2)
    : onlyDigitsNumber;

  if (cleanedNumber.length === 11) {
    return cleanedNumber.replace(/(\d{2})(\d)(\d{4})(\d{4})/, '($1) $2 $3-$4');
  } else if (cleanedNumber.length === 10) {
    return cleanedNumber.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }
};
