import {
  Dropdown as FloraDropdown,
  DropdownItem,
  CSS,
  DropdownProps as FloraDropdownProps,
} from '@grupoboticario/flora-react';

export interface DropdownItemProps {
  text: string;
  value: string;
}

interface DropdownProps extends FloraDropdownProps {
  dropdownItems: DropdownItemProps[];

  css?: CSS;
}

export const Dropdown = ({ dropdownItems, css, ...rest }: DropdownProps) => (
  <FloraDropdown css={{ ...css }} {...rest}>
    {dropdownItems?.map((item) => (
      <DropdownItem key={`${item.value}-${rest.id}`} value={item.value}>
        {item.text}
      </DropdownItem>
    ))}
  </FloraDropdown>
);
