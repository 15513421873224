import { FloraInputProps, FloraInput, Flex, Text, Box } from '@grupoboticario/flora-react';
import { useState, useRef } from 'react';

interface InputFieldPropsType extends FloraInputProps {
  focusMessage?: string;
}

export const InputField = ({ focusMessage, ...rest }: InputFieldPropsType) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const componentRef = useRef<HTMLInputElement>(null);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  return (
    <Box>
      <Flex
        direction={'column'}
        css={{
          marginBottom: '$2',
          '& span': {
            fontSize: '0.9rem',
            fontWeight: 'normal',
          },
        }}
      >
        <FloraInput
          ref={componentRef}
          data-testid='input-controlled'
          css={{
            height: '$14',
            fontSize: '12px',
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
        />
      </Flex>

      {isFocused && focusMessage && (
        <Text size='exceptionsAuxiliarRegular' weight='regular' css={{ paddingBottom: '$2' }}>
          {focusMessage}
        </Text>
      )}
    </Box>
  );
};
