import { MailingStoreControlStateProps } from '@/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { controlMailingSlice } from './controlMailingSlice';

export const useControlMailingStore = create<MailingStoreControlStateProps>()(
  devtools((...state) => ({
    ...controlMailingSlice(...state),
  }))
);
