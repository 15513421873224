import { fetcher } from '@/api/fetcher';
import { baseControlUrl } from './baseControlUrl';

interface AssistantTeamResponsible {
  name: string;
  email: string;
}

export interface AssistantTeam {
  _id: string;
  name: string;
  responsible: AssistantTeamResponsible[];
}

export const getAssistantTeams = async (): Promise<AssistantTeam[]> => {
  const { data } = await fetcher.get(`${baseControlUrl}list-teams`);

  return data;
};
