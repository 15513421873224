import { getDownloadDialerCsv } from '@/api/services/control';
import { FilterOptions } from '@/types/control';
import { displayToaster, useSlowResponseAlert } from '@/utils';
import { FloraButton } from '@grupoboticario/flora-react';
import { useState } from 'react';

interface ExportIrDataButtonProps {
  isBaseEmpty?: boolean;
  data: FilterOptions;
}

export const ExportIrDataButton = ({ isBaseEmpty = false, data }: ExportIrDataButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const setShowSlowResponseAlert = useSlowResponseAlert({
    message: 'Seu arquivo está sendo processado. Por favor, aguarde',
  });
  const handleDownloadDialerCsv = async () => {
    try {
      setIsDownloading(true);
      setShowSlowResponseAlert(true);
      const response = await getDownloadDialerCsv(data);
      const urlObject = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = urlObject;
      link.download = 'mailing.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlObject);
      displayToaster({
        message: 'Dados exportados com sucesso!',
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      displayToaster({
        message: 'Um erro ocorreu ao carregar os dados. Tente novamente.',
        kind: 'error',
      });
    }
    setIsDownloading(false);
    setShowSlowResponseAlert(false);
  };
  return (
    <FloraButton
      hierarchy='secondary'
      onClick={handleDownloadDialerCsv}
      disabled={isDownloading || isBaseEmpty || !data}
      isLoading={isDownloading}
    >
      Exportar dados das IRs
    </FloraButton>
  );
};
