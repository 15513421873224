import { KeyNames, PrefixNames } from '../../enuns';
import { formatterTagValueFromDate } from '../formatterTagValueFromDate';
import { formatterTagValueFromInput } from '../formatterTagValueFromInput';

export const formatterFilterTagToString = (filterTag: string) => {
  const cpCodeToString = formatterTagValueFromInput(filterTag, KeyNames.cpCode, PrefixNames.cpCode);
  const assistantsToString = formatterTagValueFromInput(
    filterTag,
    KeyNames.redistributionIr,
    PrefixNames.redistributionIr
  );
  const initialRegistrationDate = formatterTagValueFromDate(
    filterTag,
    KeyNames.startDate,
    PrefixNames.startDate
  );
  const endRegistrationDate = formatterTagValueFromDate(
    filterTag,
    KeyNames.endDate,
    PrefixNames.endDate
  );
  const startDateDistributionData = formatterTagValueFromDate(
    filterTag,
    KeyNames.startDateDistributionData,
    PrefixNames.startDateDistributionData
  );
  const endDateDistributionData = formatterTagValueFromDate(
    filterTag,
    KeyNames.endDateDistributionData,
    PrefixNames.endDateDistributionData
  );
  const reRegistrationStartDate = formatterTagValueFromDate(
    filterTag,
    KeyNames.reRegistrationStartDate,
    PrefixNames.reRegistrationStartDate
  );
  const reRegistrationEndDate = formatterTagValueFromDate(
    filterTag,
    KeyNames.reRegistrationEndDate,
    PrefixNames.reRegistrationEndDate
  );

  const label = formatterTagValueFromInput(filterTag, KeyNames.label, PrefixNames.label);

  return {
    ...cpCodeToString,
    ...assistantsToString,
    ...initialRegistrationDate,
    ...endRegistrationDate,
    ...startDateDistributionData,
    ...endDateDistributionData,
    ...reRegistrationStartDate,
    ...reRegistrationEndDate,
    ...label,
  };
};
