import { OperationEnum } from '@/types';

const OPERATION_PREFIX = 'operation:';
export const operationOptions = [
  {
    label: 'Boticário',
    value: `${OPERATION_PREFIX}${OperationEnum.BOTI}`,
  },
  {
    label: 'VDF',
    value: `${OPERATION_PREFIX}${OperationEnum.VDF}`,
  },
  {
    label: 'CII',
    value: `${OPERATION_PREFIX}${OperationEnum.CII}`,
  },
];
