import { toLocaleDate } from '@grupoboticario/vdi-mfe-utils';
import { KeyNames, PrefixNames } from '../../enuns';

export const formatterTagValueFromDate = (
  inputValue: string,
  keyNames: KeyNames,
  prefixNames: PrefixNames
): Record<string, string> => {
  const [key] = inputValue.split(':');
  const dateFormatted = toLocaleDate(inputValue.replace(`${keyNames}:`, ''));
  if (key === keyNames) {
    return {
      name: `${prefixNames}${dateFormatted}`,
    };
  }
};
