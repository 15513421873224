import { Table } from '@tanstack/react-table';
import { RiskAnalysis } from '../reseller';

export enum OperationEnum {
  BOTI = 'CI BOTI',
  VDF = 'VDF',
  CII = 'CII',
}

export interface MailingData {
  name: string;
  geraId: string;
  phone: string;
  riskAnalysis: RiskAnalysis;
  situation: string;
  lastService?: string;
  code: string;
  subreason: string;
  registryDate: string;
  operation: OperationEnum;
  isRecadastro: boolean;
  telRecadastro: string | null;
  startMailing: string;
  label: string;
}

export type LoadStatus = 'loading' | 'error' | 'loaded';

export interface TableState<T> {
  tableState: Table<T> | undefined;
  setTableState: (table: Table<T>) => void;
}

export interface TotalOpenServices {
  maxOpenServices: number;
  totalOpen: number;
  totalInAttendance: number;
  total: number;
}

export enum SituationStatus {
  NOT_STARTED = 'Atendimento não iniciado',
  WRONG_REGISTER = 'Cadastro por engano',
  IN_SERVICE = 'Em atendimento',
  WRONG_NUMBER = 'Número errado',
  DENIED_CALL = 'Recusa de contato',
  WITHOUT_CALL = 'Sem contato',
  SALE_COMPLETED = 'Venda realizada',
}

export enum SituationStatusValue {
  NOT_STARTED = 'notStarted',
  WRONG_REGISTER = 'wrongRegister',
  IN_SERVICE = 'inService',
  WRONG_NUMBER = 'wrongNumber',
  DENIED_CALL = 'deniedCall',
  WITHOUT_CALL = 'withoutCall',
  SALE_COMPLETED = 'saleCompleted',
}

export interface GetMailingDataParams {
  page?: number;
  limit?: number;
  field?: string;
  filterField?: string;
  filterValue?: string;
  sort?: string | undefined;
}

export interface GetMailingDataResponse {
  data: MailingData[];
  limit: number;
  page: number;
  totalDocs?: number;
  totalPages: number;
}
