import { EmptyState, Loading } from '@/shared';
import { FloraButton as Button, Flex } from '@grupoboticario/flora-react';
import { CrossCircleIcon } from '@grupoboticario/flora-react-icons';
import { LoadStatus as LoadStatusType } from '@/types';

interface LoadStatusProps {
  loadStatus: LoadStatusType;
  onRetryClick?: () => void;
}

export const LoadStatus = ({ loadStatus, onRetryClick }: LoadStatusProps) => (
  <Flex css={{ height: '100vh' }} justify='center'>
    {loadStatus === 'loading' ? (
      <Loading
        size='lg'
        message='Carregando informações'
        subtitle='Por favor, aguarde um instante'
      />
    ) : null}
    {loadStatus === 'error' ? (
      <EmptyState
        title='Não foi possível carregar os dados'
        subtitle='Por favor, tente novamente'
        icon={<CrossCircleIcon size={48} />}
        button={
          <Button size='medium' onClick={onRetryClick}>
            Tentar novamente
          </Button>
        }
      />
    ) : null}
  </Flex>
);
