import { RiskAnalysis, SituationStatusValue, SituationStatus, OperationEnum } from '@/types';
import { FilterTagType } from '../types';

const RISK_PREFIX = 'Risco:';
const SITUATION_PREFIX = 'Situação:';
const OPERATION_PREFIX = 'Operação:';
const RECADASTRO_PREFIX = 'Recadastro:';

const riskLevelToString: Record<RiskAnalysis, string> = {
  [RiskAnalysis.LOW_RISK]: `${RISK_PREFIX} Baixo`,
  [RiskAnalysis.MEDIUM_RISK]: `${RISK_PREFIX} Médio`,
  [RiskAnalysis.HIGH_RISK]: `${RISK_PREFIX} Alto`,
  [RiskAnalysis.NOT_PROCESSED]: `${RISK_PREFIX} Sem risco`,
};

const situationToString: Record<SituationStatusValue, string> = {
  [SituationStatusValue.DENIED_CALL]: `${SITUATION_PREFIX} ${SituationStatus.DENIED_CALL}`,
  [SituationStatusValue.IN_SERVICE]: `${SITUATION_PREFIX} ${SituationStatus.IN_SERVICE}`,
  [SituationStatusValue.NOT_STARTED]: `${SITUATION_PREFIX} ${SituationStatus.NOT_STARTED}`,
  [SituationStatusValue.SALE_COMPLETED]: `${SITUATION_PREFIX} ${SituationStatus.SALE_COMPLETED}`,
  [SituationStatusValue.WITHOUT_CALL]: `${SITUATION_PREFIX} ${SituationStatus.WITHOUT_CALL}`,
  [SituationStatusValue.WRONG_NUMBER]: `${SITUATION_PREFIX} ${SituationStatus.WRONG_NUMBER}`,
  [SituationStatusValue.WRONG_REGISTER]: `${SITUATION_PREFIX} ${SituationStatus.WRONG_REGISTER}`,
};

const operationToString: Record<OperationEnum, string> = {
  [OperationEnum.BOTI]: `${OPERATION_PREFIX} Boticário`,
  [OperationEnum.VDF]: `${OPERATION_PREFIX} VDF`,
  [OperationEnum.CII]: `${OPERATION_PREFIX} CII`,
};

const recadastroToString: Record<string, string> = {
  ['true']: `${RECADASTRO_PREFIX} Sim`,
  ['false']: `${RECADASTRO_PREFIX} Não`,
};

export const filterTagToString: Record<FilterTagType, string> = {
  ...riskLevelToString,
  ...situationToString,
  ...operationToString,
  ...recadastroToString,
};
