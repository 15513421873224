import { FilterOptions } from '@/types';
import { baseControlUrl } from './baseControlUrl';
import { fetcher } from '@/api/fetcher';

export const getDownloadDialerCsv = async (req: FilterOptions) => {
  const { data } = await fetcher.post(`${baseControlUrl}download-dialer-csv`, {
    body: req,
    responseType: 'blob',
    timeout: 60 * 1000 * 8,
  });

  return data;
};
