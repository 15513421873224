import { SelectDropdownItemType } from '@/shared';
import { KeyNames, PrefixNames } from '../../enuns';

export const formatterTagValueFromSelect = (
  objValue: SelectDropdownItemType[],
  keyNamesValue: KeyNames,
  prefixNames: PrefixNames
) =>
  Object.fromEntries(
    objValue.map(({ label, value }) => [`${keyNamesValue}:${value}`, `${prefixNames} ${label}`])
  );
