export enum PrefixNames {
  risk = 'Risco:',
  operation = 'Operação:',
  recadastro = 'Recadastro:',
  haveCredit = 'Crédito:',
  haveIrAssigned = 'IRs não distribuídas:',
  baseMediaSource = 'Origem de mídia da Base:',
  federativeUnit = 'UF:',
  tabulation = 'Tabulação:',
  cpCode = 'Código pai:',
  redistributionIr = 'Redistribuição de IRs:',
  startDate = 'Data inicial de cadastro:',
  endDate = 'Data final de cadastro:',
  startDateDistributionData = 'Data inicial de distribuição:',
  endDateDistributionData = 'Data final de distribuição:',
  reRegistrationStartDate = 'Data início Recadastro:',
  reRegistrationEndDate = 'Data fim Recadastro:',
  label = 'Campanha:',
}
