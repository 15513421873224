import { MailingQueryKey } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { getMaxOpenServices } from '../getMaxOpenServices';

export const useOpenServicesCount = () => {
  const fetchQuery = useQuery({
    queryKey: [MailingQueryKey.TOTAL_OPEN_SERVICES],
    queryFn: getMaxOpenServices,
    refetchOnWindowFocus: true,
  });

  return {
    ...fetchQuery,
    totalOpenServices: fetchQuery.data,
  };
};
