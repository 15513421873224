import { render } from '@testing-library/react';
import React from 'react';
import { Route, Routes, MemoryRouter } from 'react-router-dom';

export const injectRouter = (
  Component: React.ReactNode,
  { route = '/', history = [] as string[] } = {}
) => (
  <MemoryRouter initialEntries={history.length > 0 ? history : [route]}>{Component}</MemoryRouter>
);

interface RenderWithRouterProps {
  path: string;
  route: string;
  history: unknown[];
}
export function renderWithRouter(
  ui: React.ReactNode,
  { path = '/', route = '/', history = [] }: RenderWithRouterProps
) {
  return {
    ...render(
      <MemoryRouter initialEntries={history.length > 0 ? history : [route]}>
        <Routes>
          <Route path={path} element={ui} />
          <Route path='*' element={<div>Page not found</div>} />
        </Routes>
      </MemoryRouter>
    ),
  };
}
