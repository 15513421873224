import { LoadStatus, SelectDropdown } from '@/shared';
import {
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FloraButton as Button,
  FloraTypography,
} from '@grupoboticario/flora-react';
import { useControlMailingStore } from '@/store/control';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AssistantTeam, useGetAssistantTeams } from '@/api/services/control';

const dropDownOptions = (options: AssistantTeam[]) =>
  options?.map((team) => {
    return {
      label: team.name.replace('-', ' '),
      value: team._id,
    };
  }) ?? [];

export const SideSheetTeams = () => {
  const navigate = useNavigate();
  const { data, isLoading, error, refetch } = useGetAssistantTeams();
  const [teams, setTeams] = useState<string[]>([]);
  const { sideSheetTeamsOpen, setSideSheetTeamsOpen, setAssistantTeam } = useControlMailingStore(
    (state) => state
  );
  const submitTeamsInfo = () => {
    const setAssistantTeamFormatted =
      teams &&
      teams.map((team) => {
        const [teamId, responsibleName] = team.split(':');
        return {
          _id: teamId,
          responsible: responsibleName,
        };
      });
    setAssistantTeam(setAssistantTeamFormatted);
    navigate('/control/selecionar-assistentes');
  };

  useEffect(() => {
    setAssistantTeam([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setAssistantTeam(undefined);
    setTeams([]);
    setSideSheetTeamsOpen(false);
  };

  return (
    <Drawer
      open={sideSheetTeamsOpen}
      onOpenChange={() => setSideSheetTeamsOpen(!sideSheetTeamsOpen)}
    >
      <DrawerContent side='right'>
        <DrawerCloseButton />
        <DrawerHeader
          css={{
            borderBottom: '1px solid $nonInteractivePredominant',
          }}
        >
          <FloraTypography
            fontSize='title'
            viewport='desktop'
            fontWeight='regular'
            as='h5'
            data-testid='drawer-header-title'
          >
            Distribuição de IRs
          </FloraTypography>
        </DrawerHeader>
        <DrawerBody css={{ height: '100%' }} id='filter-control'>
          {isLoading || error ? (
            <LoadStatus loadStatus={error ? 'error' : 'loading'} onRetryClick={refetch} />
          ) : (
            <SelectDropdown
              id='teams'
              selectDropdownItem={dropDownOptions(data) ?? []}
              onChange={(e) => setTeams(e.target.value as unknown as string[])}
              labelText='Equipe:'
              placeholder='Selecione uma ou mais'
              multiple
            />
          )}
        </DrawerBody>
        <DrawerFooter
          css={{
            borderTop: '1px solid $nonInteractiveAltAuxiliar',
          }}
        >
          <Button hierarchy='secondary' css={{ width: '50%' }} onClick={handleCancel}>
            Cancelar
          </Button>

          <Button css={{ width: '50%' }} onClick={submitTeamsInfo} disabled={!teams?.length}>
            Selecionar equipes
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
