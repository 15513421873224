import { fetcher } from '@/api/fetcher';
import { baseControlUrl } from './baseControlUrl';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postDistributeBase = async (req: any) => {
  const { data } = await fetcher.post(`${baseControlUrl}base-irs/distribute`, {
    body: req,
    timeout: 60 * 1000 * 8,
  });

  return data;
};
