import { navigateToUrl } from 'single-spa';
import { displayToaster } from '@/utils';
import { AssistantModalOpenType } from '@/types';
import { useAssistantMailingStore } from '@/store/assistant';
import { postServices } from '../postServices';

export const useMassStartService = () => {
  const { tableMailing } = useAssistantMailingStore((state) => state);
  const selectedRows = tableMailing.tableState.getSelectedRowModel().rows;

  const handleStartService = async (attendanceStatus: AssistantModalOpenType) => {
    try {
      if (selectedRows) {
        const selectedRowsGeraId = selectedRows.map((row) => row.original.geraId);
        const data = await postServices({
          resellerId: selectedRowsGeraId,
          status: attendanceStatus,
        });
        const url =
          selectedRows.length === 1 ? `/inicios/atendimento/${data[0]}` : `/inicios/atendimento/`;

        navigateToUrl(url);
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      displayToaster({
        message: 'Não foi possível iniciar o atendimento, tente novamente.',
        kind: 'error',
      });
    }
  };

  return { handleStartService };
};
