import { updateResellerSituation } from '@/api/services/situation';
import { Dropdown, DropdownItemProps, HelperTooltip, useEvents } from '@/shared';
import { SituationStatus, SituationStatusValue } from '@/types';
import { displayToaster } from '@/utils';
import { Box, Flex } from '@grupoboticario/flora-react';
import { ChangeEvent } from 'react';

const DropdownItem: DropdownItemProps[] = [
  {
    text: 'Atendimento não iniciado',
    value: SituationStatus.NOT_STARTED,
  },
  {
    text: 'Cadastro por engano',
    value: SituationStatus.WRONG_REGISTER,
  },
  {
    text: 'Em atendimento',
    value: SituationStatus.IN_SERVICE,
  },
  {
    text: 'Número errado',
    value: SituationStatus.WRONG_NUMBER,
  },
  {
    text: 'Recusa de contato',
    value: SituationStatus.DENIED_CALL,
  },
  {
    text: 'Sem contato',
    value: SituationStatus.WITHOUT_CALL,
  },
  {
    text: 'Venda realizada',
    value: SituationStatus.SALE_COMPLETED,
  },
];

export const situationToString2: Record<SituationStatus, string> = {
  [SituationStatus.DENIED_CALL]: SituationStatusValue.DENIED_CALL,
  [SituationStatus.IN_SERVICE]: SituationStatusValue.IN_SERVICE,
  [SituationStatus.NOT_STARTED]: SituationStatusValue.NOT_STARTED,
  [SituationStatus.SALE_COMPLETED]: SituationStatusValue.SALE_COMPLETED,
  [SituationStatus.WITHOUT_CALL]: SituationStatusValue.WITHOUT_CALL,
  [SituationStatus.WRONG_NUMBER]: SituationStatusValue.WRONG_NUMBER,
  [SituationStatus.WRONG_REGISTER]: SituationStatusValue.WRONG_REGISTER,
};

const situationToString = new Map<SituationStatusValue, string>([
  [SituationStatusValue.DENIED_CALL, SituationStatus.DENIED_CALL],
  [SituationStatusValue.IN_SERVICE, SituationStatus.IN_SERVICE],
  [SituationStatusValue.NOT_STARTED, SituationStatus.NOT_STARTED],
  [SituationStatusValue.SALE_COMPLETED, SituationStatus.SALE_COMPLETED],
  [SituationStatusValue.WITHOUT_CALL, SituationStatus.WITHOUT_CALL],
  [SituationStatusValue.WRONG_NUMBER, SituationStatus.WRONG_NUMBER],
  [SituationStatusValue.WRONG_REGISTER, SituationStatus.WRONG_REGISTER],
]);

interface DropdownSituationProps {
  code: string;
  subreason: string;
  dropdownValue: SituationStatusValue;
  geraId: string;
}

export const DropdownSituation = ({
  code,
  subreason,
  dropdownValue,
  geraId,
}: DropdownSituationProps) => {
  const events = useEvents();
  const onDropdownChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const data = {
      status: [{ geraId: geraId, status: e.target.value }],
    };

    try {
      await updateResellerSituation(data);
      events.interactionDropdownSituation(e.target.value, geraId);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      displayToaster({
        message: 'Um erro ocorreu ao carregar os dados. Tente novamente.',
        kind: 'error',
      });
    }
  };

  const textTooltip = `Motivo: ${code} - ${subreason}`;

  return (
    <Flex
      data-tesid='dropdown-situation'
      align='center'
      css={{
        marginTop: '$5',
        width: '200px',
      }}
    >
      <Dropdown
        id={`situation-${geraId}`}
        labelText='Selecione'
        dropdownItems={DropdownItem}
        onChange={onDropdownChange}
        defaultValue={situationToString.get(dropdownValue)}
        css={{
          width: '150px',
        }}
      />
      <Box css={{ position: 'relative', top: '-10px' }}>
        <HelperTooltip text={code && subreason ? textTooltip : '--'} />
      </Box>
    </Flex>
  );
};
