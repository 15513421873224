import { ControlQueryKey } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { getMetadata } from '../getMetadata';

export const useGetMetaData = () => {
  const fetchQuery = useQuery({
    queryKey: [ControlQueryKey.METADATA],
    queryFn: getMetadata,
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 5,
  });

  return {
    ...fetchQuery,
    isLoading: !fetchQuery.data && !fetchQuery.error,
    data: fetchQuery.data,
  };
};
