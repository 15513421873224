import { Grid } from '@grupoboticario/flora-react';
import { TextColorsEnum } from '@/types';
import {
  TwoPeopleIcon,
  PersonPlusIcon,
  DollarSignCircleIcon,
  ExclamationMarkHexagonIcon,
} from '@grupoboticario/flora-react-icons';
import { useControlMailingStore } from '@/store/control';
import { useShallow } from 'zustand/react/shallow';
import { IrCardInfo } from '@/components/control';
import { numberFormatter } from '@/shared/utils';

const fallbackValue = '0';

export const IrDistributionDataControl = () => {
  const { baseIrsData } = useControlMailingStore(useShallow((state) => state));
  const data = baseIrsData && baseIrsData[0];

  const formattedDataIR = [
    {
      title: 'IRs na base',
      icon: <TwoPeopleIcon />,
      helpText: 'Quantidade de Intenções de Revenda (IRs) na base atual.',
      textInfo: numberFormatter(data?.baseIr) ?? fallbackValue,
    },
    {
      title: 'Crédito',
      icon: <DollarSignCircleIcon />,
      helpText:
        'Intenções de Revenda (IRs) que receberam ou não crédito para compra com boleto a prazo.',
      textInfo: [
        {
          title: 'Sim',
          value: data?.credit.haveCredit ?? fallbackValue,
          color: TextColorsEnum.NO_COLOR,
        },
        {
          title: 'Não',
          value: data?.credit.notHaveCredit ?? fallbackValue,
          color: TextColorsEnum.NO_COLOR,
        },
      ],
    },
    {
      title: 'IRs não distribuídas',
      icon: <PersonPlusIcon />,
      helpText:
        'Quantidade de Intenções de Revenda (IRs) que ainda não foram alocadas para times ou assistentes das operações.',
      textInfo: numberFormatter(data?.irNotAssigned) ?? fallbackValue,
    },
    {
      title: 'Nível de risco',
      icon: <ExclamationMarkHexagonIcon />,
      helpText: 'Grau de risco de fraude calculado para as Intenções de Revenda (IRs)',
      textInfo: [
        {
          title: 'Baixo',
          value: data?.riskScore.low ?? fallbackValue,
          color: TextColorsEnum.NO_COLOR,
        },
        {
          title: 'Médio',
          value: data?.riskScore.medium ?? fallbackValue,
          color: TextColorsEnum.NO_COLOR,
        },
        {
          title: 'Alto',
          value: data?.riskScore.high ?? fallbackValue,
          color: TextColorsEnum.NO_COLOR,
        },
        {
          title: 'Sem risco',
          value: data?.riskScore.noRisk ?? fallbackValue,
          color: TextColorsEnum.NO_COLOR,
        },
      ],
    },
  ];
  return (
    <Grid
      templateColumns='repeat(2, 6fr)'
      gapX='$12'
      gapY='$5'
      css={{
        position: 'relative',
        marginTop: '$6',
        minWidth: '992px',
        '&::before': {
          borderLeft: '1px solid $nonInteractiveOutline',
          height: '100%',
          content: ' ',
          position: 'absolute',
          top: '0',
          zIndex: '1',
          left: '50%',
        },
      }}
    >
      {formattedDataIR.map((dataIr) => (
        <IrCardInfo
          key={dataIr.title}
          title={dataIr.title}
          icon={dataIr.icon}
          helpText={dataIr.helpText}
          textInfo={dataIr.textInfo}
        />
      ))}
    </Grid>
  );
};
