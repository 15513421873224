import { Box, FloraButton, FloraButtonProps } from '@grupoboticario/flora-react';
import { ThreeLinesHorizontal2Icon } from '@grupoboticario/flora-react-icons';

interface FilterButtonProps {
  onClick: () => void;
  size?: FloraButtonProps['size'];
  filterCount?: string | number;
}

export const FilterButton = ({ onClick, size, filterCount }: FilterButtonProps) => (
  <Box css={{ position: 'relative' }}>
    {filterCount ? (
      <FloraButton
        data-testid='filter-count'
        size='small'
        css={{
          borderRadius: '$large',
          width: '$6',
          height: '$6',
          fontSize: '$fontSizes$exceptionsAuxiliar',
          position: 'absolute',
          right: '-10px',
          top: '-10px',
        }}
      >
        {filterCount}
      </FloraButton>
    ) : null}

    <FloraButton
      hierarchy='secondary'
      data-testid='btn-filter'
      has='iconLeft'
      size={size}
      onClick={onClick}
      icon={<ThreeLinesHorizontal2Icon />}
    >
      Filtros
    </FloraButton>
  </Box>
);
