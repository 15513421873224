import { OperationEnum, RiskAnalysisControl } from '@/types';

enum PrefixNames {
  risk = 'Risco:',
  operation = 'Operação:',
  recadastro = 'Recadastro:',
  haveCredit = 'Crédito:',
  haveIrAssigned = 'IRs não distribuídas:',
  baseMediaSource = 'Origem de mídia da Base:',
}

enum KeyNames {
  haveCredit = 'haveCredit',
  haveIrAssigned = 'haveIrAssigned',
  operation = 'operation',
  recadastro = 'recadastro',
  riskAnalysis = 'riskAnalysis',
  tabulation = 'tabulation',
  startDate = 'startDate',
  endDate = 'endDate',
  redistributionIr = 'assistants',
  baseMediaSource = 'baseMediaSource',
}

const riskLevelToString: Record<string, string> = {
  [`${KeyNames.riskAnalysis}:${RiskAnalysisControl.LOW_RISK}`]: `${PrefixNames.risk} Baixo`,
  [`${KeyNames.riskAnalysis}:${RiskAnalysisControl.MEDIUM_RISK}`]: `${PrefixNames.risk} Médio`,
  [`${KeyNames.riskAnalysis}:${RiskAnalysisControl.HIGH_RISK}`]: `${PrefixNames.risk} Alto`,
  [`${KeyNames.riskAnalysis}:${RiskAnalysisControl.NOT_PROCESSED}`]: `${PrefixNames.risk} Sem risco`,
};

const operationToString: Record<`${KeyNames.operation}:${OperationEnum}`, string> = {
  [`${KeyNames.operation}:${OperationEnum.BOTI}`]: `${PrefixNames.operation} Boticário`,
  [`${KeyNames.operation}:${OperationEnum.VDF}`]: `${PrefixNames.operation} VDF`,
  [`${KeyNames.operation}:${OperationEnum.CII}`]: `${PrefixNames.operation} CII`,
};

const recadastroToString: Record<string, string> = {
  [`${KeyNames.recadastro}:sim`]: `${PrefixNames.recadastro} Sim`,
  [`${KeyNames.recadastro}:nao`]: `${PrefixNames.recadastro} Não`,
};

const haveCreditToString: Record<string, string> = {
  [`${KeyNames.haveCredit}:sim`]: `${PrefixNames.haveCredit} Sim`,
  [`${KeyNames.haveCredit}:nao`]: `${PrefixNames.haveCredit} Não`,
};

const haveIrAssignedToString: Record<string, string> = {
  [`${KeyNames.haveIrAssigned}:sim`]: `${PrefixNames.haveIrAssigned} Sim`,
  [`${KeyNames.haveIrAssigned}:nao`]: `${PrefixNames.haveIrAssigned} Não`,
};

const baseMediaSourceToString: Record<string, string> = {
  [`${KeyNames.baseMediaSource}:outros - organico`]: `${PrefixNames.baseMediaSource} Orgânico`,
  [`${KeyNames.baseMediaSource}: portais - organico`]: `${PrefixNames.baseMediaSource} Orgânico`,
  [`${KeyNames.baseMediaSource}:afiliados - pago`]: `${PrefixNames.baseMediaSource} Pago`,
  [`${KeyNames.baseMediaSource}: facebook - pago`]: `${PrefixNames.baseMediaSource} Pago`,
  [`${KeyNames.baseMediaSource}: outros - pago`]: `${PrefixNames.baseMediaSource} Pago`,
  [`${KeyNames.baseMediaSource}: google - pago`]: `${PrefixNames.baseMediaSource} Pago`,
};

export const filterTagToString: Record<string | number | symbol, string> = {
  ...haveCreditToString,
  ...haveIrAssignedToString,
  ...operationToString,
  ...recadastroToString,
  ...riskLevelToString,
  ...baseMediaSourceToString,
};
