import { FilterTagList } from '@/shared';
import { useControlMailingStore } from '@/store/control';
import { FilterOptions } from '@/types';
import { useShallow } from 'zustand/react/shallow';
import { filterTagToString } from './constants';
import { toLocaleDate } from '@grupoboticario/vdi-mfe-utils';
import { Box } from '@grupoboticario/flora-react';

const convertToFilterTag = (filterList: FilterOptions): string[] =>
  !!filterList &&
  Object.entries(filterList).flatMap(([key, value]) =>
    Array.isArray(value) ? value.map((v) => `${key}:${v}`) : `${key}:${value}`
  );

const formatterFilterTag = (filterTag: FilterOptions) => {
  if (!filterTag) {
    return [];
  }
  const convertedFilterTag = convertToFilterTag(filterTag);
  const dates: string[] = [];
  const result = convertedFilterTag.reduce((acc, item) => {
    const [, key, value] = item.match(/([^:]+):(.*)/);

    switch (key) {
      case 'tabulation':
        return [...acc, { name: `Tabulação:${value}`, key: `${key}-${value}` }];
      case 'cpCode':
        return [...acc, { name: `Código pai:${value}`, key: `${key}-${value}` }];
      case 'assistants':
        return [...acc, { name: `Redistribuição de IRs: ${value}`, key: `${key}-${value}` }];
      case 'startDate':
      case 'endDate':
        dates.push(toLocaleDate(value));
        return acc;
      default:
        return [
          ...acc,
          {
            name: filterTagToString[item] || item,
            key: item,
          },
        ];
    }
  }, []);

  if (dates.length > 0) {
    const dateLabel = `Data: ${dates.join('-')}`;
    result.push({
      name: dateLabel,
      key: 'date',
    });
  }

  return result;
};

export const ControlFiler = () => {
  const { controlFilter } = useControlMailingStore(useShallow((state) => state));

  return (
    <Box>
      <FilterTagList filterTagResult={formatterFilterTag(controlFilter)} variant='disabled' />
    </Box>
  );
};
