import { OperationEnum } from '@/types';

export const tabulationOptions = [
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '20', value: '20' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
  { label: '25', value: '25' },
  { label: '26', value: '26' },
  { label: '27', value: '27' },
  { label: '28', value: '28' },
  { label: '29', value: '29' },
];

export const operationOptions = [
  {
    label: 'Boticário',
    value: OperationEnum.BOTI,
  },
  {
    label: 'VDF',
    value: OperationEnum.VDF,
  },
  {
    label: 'CII',
    value: OperationEnum.CII,
  },
];

export const singleSelectOptions = [
  {
    label: 'Sim',
    value: 'sim',
  },
  {
    label: 'Não',
    value: 'nao',
  },
  {
    label: 'Todos',
    value: 'undefined',
  },
];

const baseMediaSourcePaidValueOptions: string =
  'afiliados - pago, facebook - pago, outros - pago, google - pago';

const baseMediaSourceOrganicValueOptions: string = 'outros - organico, portais - organico';

export const organicFilterOptions = [
  {
    label: 'Orgânica',
    value: baseMediaSourceOrganicValueOptions,
  },
  {
    label: 'Pago',
    value: baseMediaSourcePaidValueOptions,
  },
  {
    label: 'Todos',
    value: undefined,
  },
];
