import { ControlQueryKey } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { useControlMailingStore } from '@/store/control';
import { useShallow } from 'zustand/react/shallow';
import { getAssistantTeamMembers } from '../getAssistantTeamMembers';
import { useNavigate } from 'react-router-dom';

export const useGetAssistantTeamMembers = () => {
  const navigate = useNavigate();
  const { assistantTeam } = useControlMailingStore(useShallow((state) => state));
  const groupIds = assistantTeam && assistantTeam?.map((team) => team._id);
  const queryKeyAssistantList = [ControlQueryKey.ASSISTANT_TEAMS, groupIds];
  const fetchQuery = useQuery({
    queryKey: queryKeyAssistantList,
    queryFn: () => getAssistantTeamMembers({ queryParams: { groupId: groupIds.join(',') } }),
  });

  if (!assistantTeam?.length) {
    navigate('/control/distribuicao-irs');
  }

  return {
    ...fetchQuery,
    data: fetchQuery.data,
  };
};
