import { BaseIrs, FilterOptions } from '@/types';
import { baseControlUrl } from './baseControlUrl';
import { fetcher } from '@/api/fetcher';

export const getBaseIrsData = async (req: FilterOptions): Promise<BaseIrs[]> => {
  const { data } = await fetcher.post(`${baseControlUrl}base-irs`, {
    body: req,
  });

  return data;
};
