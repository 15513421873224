export enum RiskAnalysis {
  LOW_RISK = 'lowRisk',
  MEDIUM_RISK = 'mediumRisk',
  HIGH_RISK = 'highRisk',
  NOT_PROCESSED = 'notProcessed',
}

export enum RiskAnalysisControl {
  LOW_RISK = 'BAIXO RISCO',
  MEDIUM_RISK = 'MEDIO RISCO',
  HIGH_RISK = 'ALTO RISCO',
  NOT_PROCESSED = 'undefined',
}
