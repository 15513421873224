import { Box, CSS } from '@grupoboticario/flora-react';

interface DividerProps {
  css?: CSS;
}

const Divider = ({ css }: DividerProps): JSX.Element => (
  <Box
    css={{
      height: '1px',
      width: '100%',
      background: '$statusAlertNonInteractivePredominant',
      filter: 'opacity(0.2)',
      margin: '$2 0',
      ...css,
    }}
  />
);

export { Divider };
