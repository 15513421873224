import { Flex } from '@grupoboticario/flora-react';
import { ChevronUpIcon, ChevronDownIcon } from '@grupoboticario/flora-react-icons';

export const SortIcon = () => (
  <Flex direction='column' css={{ maxHeight: '30px', paddingLeft: '$1' }}>
    <ChevronUpIcon
      css={{
        width: '$4',
        position: 'relative',
        top: '-2px',
      }}
    />
    <ChevronDownIcon
      css={{
        width: '$4',
        position: 'relative',
        top: '-16px',
      }}
    />
  </Flex>
);
