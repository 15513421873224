import { api as baseURL } from '@/config';
import { authService } from '@vdi/auth-service';
import axios from 'axios';

const instance = axios.create({
  baseURL,
});
instance.interceptors.request.use(async (config) => {
  const { token } = await authService.getTokenOrRedirect();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export { instance };
