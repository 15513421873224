import DateSelector, { DatePickerProps } from 'react-datepicker';
import { Box, FloraInput } from '@grupoboticario/flora-react';

import 'react-datepicker/dist/react-datepicker.css';

export const DatePicker = ({ ...rest }: DatePickerProps) => {
  return (
    <Box
      css={{
        width: '100%',
        paddingBottom: '$4',
        '& div.react-datepicker-popper': {
          zIndex: 1000,
        },
        '& > div': {
          width: 'inherit',
        },
        '& input': {
          padding: '$3',
          fontSize: '$fontSizes$bodySmall',
          '&::placeholder': {
            color: '$colors$nonInteractivePredominant',
          },
        },
      }}
    >
      <DateSelector
        dateFormat='dd/MM/yyyy'
        {...rest}
        customInput={<FloraInput id={rest.name} label='' />}
      />
    </Box>
  );
};
