import { useState, useEffect } from 'react';
import { displayToaster } from '../display-toaster';

interface UseSlowResponseAlertProps {
  delayInSeconds?: number;
  message?: string;
}
export const useSlowResponseAlert = ({
  delayInSeconds = 30,
  message,
}: UseSlowResponseAlertProps) => {
  const [showSlowResponseAlert, setShowSlowResponseAlert] = useState(false);

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout;
    if (showSlowResponseAlert) {
      timeoutId = setTimeout(() => {
        displayToaster({
          message: message,
          kind: 'alert',
        });
      }, delayInSeconds * 1000);
    }

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSlowResponseAlert, delayInSeconds]);

  return setShowSlowResponseAlert;
};
