import { fetcher } from '@/api/fetcher';

export interface MetadataResponse {
  mailingLastUpdate: string;
}

export const getMetadata = async (): Promise<MetadataResponse[]> => {
  const { data } = await fetcher.get('mailing/metadata');

  return data;
};
